<template>

  <div>

    <!-- Table Container Card -->
    <b-card
        no-body
        class="mb-0"
    >

      <div class="m-2">
        <b-card

        >
          <b-form
                  @submit.prevent="searchByCondition"
                  @reset.prevent="resetCondition"
          >
            <b-row>
              <!-- 查询条件     -->

              <b-col
                      cols="12"
                      md="4"
              >
                <xy-input-button
                        label="渠道主体"
                        type="input"
                        v-on:change="fromChildren($event,['company_name','company_id'])"
                        :params="['company_name','company_id']"
                        :value="state.condition.company_name"
                        modalName="渠道方"
                        placeholder="点击选择渠道主体"
                >
                </xy-input-button>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <xy-input-button
                        label="公司主体"
                        type="input"
                        v-on:change="fromChildren($event,['companyname','companyid'])"
                        :params="['companyname','companyid']"
                        :value="state.condition.companyname"
                        modalName="销售主体"
                        placeholder="点击选择销售主体"
                >
                </xy-input-button>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="店铺名称:"
                        label-for="store_name"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.store_name"
                          name="store_name"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="店铺名称"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="店铺ID:"
                        label-for="storeid"
                        label-size="sm"

                >
                  <b-form-input
                          v-model="state.condition.storeid"
                          name="storeid"
                          class="d-inline-block mr-1"
                          size="sm"
                          placeholder="店铺ID"
                  />
                </b-form-group>
              </b-col>


              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="店铺状态:"
                        label-for="store_store_status"
                        label-size="sm"
                >
                  <v-select
                          id="store_store_status"
                          :options="getCodeOptions('store_store_status')"
                          v-model="state.condition.store_status"
                          name="store_store_status"
                          class="select-size-sm"
                          placeholder="请选择状态"
                          :reduce="item=>item.value"
                  />
                </b-form-group>
              </b-col>

              <b-col
                      cols="12"
                      md="4"
              >
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="店铺提现状态:"
                        label-for="get_cash_status"
                        label-size="sm"
                >
                  <v-select
                          id="status"
                          :options="getCodeOptions('get_cash_status')"
                          v-model="state.condition.get_cash_status"
                          name="get_cash_status"
                          class="select-size-sm"
                          placeholder="店铺提现状态"
                          :reduce="item=>item.value"
                  />
                </b-form-group>
              </b-col>


              <b-col
                      cols="12"
                      md="12"
                      class="demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>

                <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="outline-primary"
                        type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>

              </b-col>
            </b-row>
          </b-form>
        </b-card>
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
              cols="12"
              md="6"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col
              cols="12"
              md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  variant="primary"
                  :to="{ name: 'apps-store-edit'}"
              >
                <span class="text-nowrap">添加</span>
              </b-button>

              <b-button variant="primary"
                        @click="pddToken"
                        style="margin-left: 10px">
                <span class="text-nowrap">Token</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
          select-mode="single"
          selectable
          @row-selected="selectAllRow"
      >

        <!-- Columns -->
        <template #cell(store_id)="data">
          <!--          <b-link-->
          <!--              :to="{ name: 'apps-store-edit', query: { id: data.item.id } }"-->
          <!--              class="font-weight-bold d-block text-nowrap"-->
          <!--          >-->
          #{{ data.item.store_id }}
          <!--          </b-link>-->
        </template>

        <!--bbm -->
        <template #cell(bbmall)="data">
          {{ data.item.bbm_team_id }}<br/>
          {{ data.item.bbm_team_code }}
        </template>

        <!-- Column: Type -->
        <template #cell(store_type)="data">
          {{ getCodeLabel('store_type', data.item.store_type) }}
        </template>

        <!-- Column: create_time -->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!-- Column: create_time -->
        <template #cell(expire_time_token)="data">
          {{ toTime(data.item.expire_time_token) }}
        </template>

        <template #cell(store_team)="data">
          {{ getCodeLabel('store_team', data.item.store_team) }}
        </template>

        <template #cell(status)="data">
          {{ getCodeLabel('store_status', data.item.status) }}
        </template>

        <template #cell(store_user)="data">
          {{ getCodeLabel('user', data.item.ext.store_user) }}
        </template>

        <template #cell(logintel_care)="data">
          {{ getCodeLabel('user', data.item.logintel_care) }}
        </template>


        <template #cell(store_status)="data">
          {{ getCodeLabel('store_store_status', data.item.store_status) }}
        </template>

        <template #cell(get_cash_status)="data">
          {{ getCodeLabel('get_cash_status', data.item.get_cash_status) }}
        </template>

        <template #cell(sales_type)="data">
          {{ getCodeLabel('store_sales_type', data.item.sales_type) }}
        </template>

        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <template #cell(attachments)="data">
          <attachment-upload :theme="'preview-dropdown'"
                             :attachment_id="'attachments'"
                             :id="data.item.attachments"
                             :object_type="'store'"
                             :object_id="0"
                             :readonly="true"
                             @change=""
          />
        </template>


        <template #cell(creator)="data">
          {{ getCodeLabel('user', data.item.creator) }}
        </template>

        <template #cell(checker)="data">
          {{ getCodeLabel('user', data.item.checker) }}
        </template>

        <!-- Column: image -->
        <template #cell(image)="data">
          <b-img :src="path + '/' + data.item.image" style="width: 40px; height: 40px" fluid alt="暂无图片"></b-img>
        </template>

        <!-- Column: State -->
        <template #cell(state)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('state', data.item.state)}`"
          >
            {{ getCodeLabel('state', data.item.state) }}
          </b-badge>
        </template>

        <template #cell(is_noorder)="data">
          <feather-icon
                  icon="XIcon"
                  size="21"
                  v-if="isNumber(data.item.is_noorder)==0"
          />
          <feather-icon
                  icon="CheckIcon"
                  size="21"
                  v-else
          />
        </template>

        <!--OA开票申请人-->
        <template #cell(invoice_oa_userid)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!--提现状态-->
        <template #cell(get_cash_status)="data">
          {{ getCodeLabel('get_cash_status', data.item.get_cash_status) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
              variant="link"
              no-caret
          >

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <div v-if="data.item.status===2||data.item.status===5">
              <b-dropdown-item @click="submit(data.item)">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">提交</span>
              </b-dropdown-item>
            </div>

            <b-dropdown-item @click="updateToken(data.item.store_no)">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">更新token</span>
            </b-dropdown-item>

            <div v-if="data.item.status!==3">
              <b-dropdown-item :to="{ name: 'apps-store-edit', query: { id: data.item.id } }">
                <feather-icon icon="EditIcon"/>
                <span class="align-middle ml-50">编辑</span>
              </b-dropdown-item>
            </div>

            <b-dropdown-item :to="{ name: 'apps-storecontact-list', query: { storeId: data.item.id } }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑负责人</span>
            </b-dropdown-item>

            <b-dropdown-item :to="{ name: 'apps-storesales-list', query: { storeId: data.item.id ,type:1} }">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑销售员</span>
            </b-dropdown-item>

            <b-dropdown-item v-b-modal.modal-store-log @click="changeModifyLog(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">修改日志</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="data.item.company_id == 90" @click="pushBBM(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">推送至BBMall</span>
            </b-dropdown-item>

            <b-dropdown-item v-if="user.role_id ===14 || user.role_id ===29 || user.role_id ===31" @click="noOrderModel(data.item)">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">无源退货管理</span>
            </b-dropdown-item>

          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <b-modal
          id="modal-store-log"
          hide-footer
          centered
          size="xl"
          title="修改日志"
      >
        <modify-log-list :objectTypeProduct="3" :productid="id"></modify-log-list>
      </b-modal>

      <b-modal
              id="noOrderReturnModel"
              size="lg"
              title="无源品牌新增"
              ref="noOrderReturnModel"
              ok-only
              ok-title="确认"
              @ok="submitInfo"
              cancel-title="取消"
              centered
      >
        <div>
          <b-col md="12">
            <b-row>
              <b-col md="6">
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="团队名称"
                        label-for="storeNameModel"
                        label-size="sm"
                        class="mb-1 "
                >
                  <b-form-input
                          id="storeNameModel"
                          size="sm"
                          v-model="storeNameModel"
                          readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
              </b-col>
              <b-col md="6">
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="允许无源"
                        label-for="isNoorder"
                        label-size="sm"
                        class="mb-1 readonly"
                >
                  <v-select
                          id="car_detail"
                          :options="getCodeOptions('yesno')"
                          class="select-size-sm"
                          v-model="isNoorder"
                          :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col
                      cols="12"
                      md="6"
              >
                <v-select
                        id="brand"
                        v-model="brandListSelect"
                        multiple
                        label="name"
                        class="flex-grow-1 email-to-selector scrollable"
                        :close-on-select="false"
                        :options="brandList"
                        :filterable="false"
                        placeholder="请输入品牌名称(请输入至少两个字符)"
                        @search="loadBrandOptions"
                        :dropdown-should-open="openSelect"
                >

                  <template #option="{  name }">
                    <span class="ml-50"> |  {{ name }} </span>
                  </template>

                  <template #selected-option="{ name }" style="background-color: cornsilk">
                    <span class="ml-50"> {{ name }}</span>
                  </template>
                </v-select>
              </b-col>
            </b-row>
          </b-col>
        </div>

      </b-modal>

    </b-card>
  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted, reactive, onMounted, toRefs} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor,isNumber} from '@core/utils/filter'
import storeUseList from './storeUseListAll'
import storeStore from './storeStore'
import brandStore from '@/views/apps/brand/brandStore'
import axios from "@/libs/axios";
import AttachmentUpload from "@/views/apps/attachment/AttachmentUpload";
import ModifyLogList from "@/views/apps/modifylog/ModifyLogList";
import {useToast} from "vue-toastification/composition";
import XyInputButton from "@/views/components/xy/XyInputButton";
import {getUserData} from "@/auth/utils";
import Ripple from "vue-ripple-directive";
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    AttachmentUpload,
    ModifyLogList,
    XyInputButton
  },
  data() {
    return {
      path: null,
      selected: [],
      id: 0,
      user:{},
      storeNameModel:'',
      isNoorder:'',
      brandListSelect:[],
      brandList:[],
      searchBrandKey: '',
      storeIdModel:'',
    }
  },
  directives: {
    Ripple,
  },
  methods: {
    searchByCondition() {
      this.refetchData()
    },
    resetCondition() {
      this.state.condition = {}
      store.commit('store/updateCondition', this.state.condition)
      this.refetchData()
    },
    changeState: function (data) {
      const state = 1 - data.state
      store.dispatch('store/state', {id: data.id, state: state}).then(res => {
        data.state = state
      })
    },
    pddToken: function () {
      window.open(process.env.VUE_APP_TOKEN_URL)
    },
    getSelected: function () {
      return this.selected
    },
    changeModifyLog(data) {
      this.id = data.store_id
    },

  },
  setup() {
    // Register module
    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('brand')) store.registerModule('brand', brandStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('store')) store.unregisterModule('store')
      if (store.hasModule('brand')) store.unregisterModule('brand')
    })
    const toast = useToast()

    const updateToken = function (id) {
      // 拼多多连接跳转
      // 新增按钮，店铺授权  url: api/pdd/oauth
      // api/pdd/refresh?storeNo=店铺编码
      // 新增店铺 店铺编码不可以重复
      window.open(process.env.VUE_APP_TOKEN_URL_REFRESH + '?storeNo=' + id)
    }
    const state = reactive({
      condition: {},
    })

    onMounted(() => {
      state.condition = store.getters['store/getCondition']
    })

    const selectAllRow = function (items) {
      this.selected = items
    }

    const submit = function (data) {
      let status = 3
      let state = 0
      store.dispatch('store/submit', {id: data.store_id, state: state, status: status}).then(res => {
        toast.success("操作成功")
        refetchData()
      })
    }
    const pushBBM = function (data){
      store.dispatch('store/pushStoreToBBMall', {id: data.store_id}).then(res => {
        if(res.data.code == 0){
          toast.success("推送成功")
        }else {
          toast.error(res.data.data)
        }
        refetchData()
      })
    }
    const fromChildren = function (params, modal) {
      for (let i = 0; i < modal.length; i++) {
        this.state.condition[modal[i]] =params==null?null:params[modal[i]]
      }
      this.$forceUpdate()
    }

    const noOrderModel = function (data) {
      let brands = data.ext.brands
      this.brandListSelect = []
      if(brands.length>0){
        for(let i = 0; i < brands.length; i++){
           this.brandListSelect.push({"brand_id":brands[i].brand_id,"name":brands[i].name})
        }
      }
      console.log("noOrderModel",this.brandListSelect)
      this.storeIdModel = data.store_id
      this.storeNameModel = data.store_name
      this.isNoorder = isNumber(data.is_noorder)===0?'否':'是'
      this.$refs['noOrderReturnModel'].show()
    }

    const loadBrandOptions = function (searchBrandKey) {
      if (searchBrandKey !== '' && searchBrandKey.length >= 2) {
        this.brandList = []
        store.dispatch('brand/search', {
          start: this.start,
          limit: this.limit,
          searchKey: searchBrandKey.replaceAll("'", ""),
        }).then(res => {
          if (res.data.code !== 0) {
            return
          }
          this.brandList = res.data.data.list
        })
      }
    }

    const openSelect = function (VueSelect) {
      if (this.brandList.length > 0) {
        return VueSelect.open
      }
      return VueSelect.search.length >= 2 && VueSelect.open
    }

    const submitInfo = function () {
      let isNoorderT =  (this.isNoorder ==='1' || this.isNoorder === '0')?this.isNoorder : (this.isNoorder==='是'?1:0);
      if((isNoorderT==='1'||isNoorderT === 1) && this.brandListSelect.length === 0){
        toast.error("请选择品牌!")
        return
      }
      if((isNoorderT==='0'||isNoorderT === 0) && this.brandListSelect.length > 0){
        toast.error("请选择允许无源!")
        return
      }
      let brandIdList = []
      console.log("brandListSelect",this.brandListSelect)
      for (let i = 0; i < this.brandListSelect.length; i++) {
        brandIdList.push(this.brandListSelect[i].brand_id)
      }
      let brandIds = brandIdList.join(',')
      store.dispatch('store/saveNoOrder', {
        store_id: this.storeIdModel,
        is_noorder: isNoorderT,
        brandids: brandIds,
      }).then(res => {
        if (res.data.code == 0) {
           toast.success("保存成功!")
           this.refetchData()
        }else {
          toast.error(res.data.data)
        }
      })
    }

    const {

      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = storeUseList()

    return {
      state,
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      updateToken,
      selectAllRow,
      pushBBM,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      submit,
      fromChildren,
      isNumber,
      noOrderModel,
      loadBrandOptions,
      openSelect,
      submitInfo
    }
  },

  mounted() {
    var that = this
    that.path = process.env.VUE_APP_OSS_URL
  },
  created() {
    this.needId = 0
    this.user = getUserData()
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
