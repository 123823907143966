<template>
    <div>
      <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="省"
                    label-for="province_id"
                    label-size="sm"
                    :class="`mb-1 ${this.isRequired ? 'required' : ''}`"
            >
                <v-select
                        id="province_id"
                        :options="getProvince"
                        :clearable="true"
                        v-model="selData.provinceId"
                        @input="onChangeProvince($event)"
                        class="select-size-sm"
                        placeholder="请选择省份"
                />
            </b-form-group>

      <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="城市"
                    label-for="city_id"
                    label-size="sm"
                    :class="`mb-1 ${this.isRequired ? 'required' : ''}`"
                    v-if="levelcity"
            >
                <v-select
                        id="city_id"
                        :options="getCity"
                        :clearable="true"
                        v-model="selData.cityId"
                        @input="onChangeCity($event)"
                        class="select-size-sm"
                        placeholder="请选择城市"
                        :disabled = "disabledcity"
                />
            </b-form-group>

      <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="地区"
                    label-for="area_id"
                    label-size="sm"
                    :class="`mb-1 ${this.isRequired ? 'required' : ''}`"
                    v-if="levelarea"
            >
                <v-select
                        id="area_id"
                        :options="getArea"
                        :clearable="true"
                        v-model="selData.areaId"
                        @input="onChangeArea($event)"
                        class="select-size-sm"
                        placeholder="请选择地区"
                        :disabled="disabledarea"
                />
            </b-form-group>
    </div>

</template>

<script>
    import {
        BCard,
        BMedia,
        BAvatar,
        BCardText,
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BImg,
        BFormFile,
        BLink,
        BButton,
        BCalendar,
        BFormDatepicker,
        BCardBody,
        BCardGroup
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'
    import {computed, onUnmounted, ref} from '@vue/composition-api'
    import router from "@/router";
    import store from "@/store";
    import userStore from "@/views/apps/user/userStore";
    import {getUserData} from '@/auth/utils'
    // Notification
    import {useToast} from 'vue-toastification/composition'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import {toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor, second} from '@core/utils/filter'
    import UserSelect from '@/views/apps/user/user-modal/UserSelect'
    import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
    import flatPickr from 'vue-flatpickr-component'
    import bankaccountStore from "@/views/apps/bankaccount/bankaccountStore";
    import {useRouter} from "@core/utils/utils";
    import NavDivider from "@/views/components/nav/NavDivider";
    import areaStore from "@/views/apps/area/areaStore";


    export default {
        components: {
            NavDivider,
            UserSelect,
            BCard,
            BMedia,
            BAvatar,
            BCardText,
            BForm,
            BLink,
            BImg,
            BRow,
            BCol,
            BButton,
            BFormGroup,
            BFormInput,
            BFormFile,
            vSelect,
            useToast,
            BCalendar,
            BFormDatepicker,
            BCardBody, BCardGroup,
            flatPickr,
            AttachmentUpload
        },
        data() {
            return {
              selData: {
                    provinceId: '',
                    cityId: '',
                    areaId: '',
                },

                getProvince: [],
                getCity: [],
                getArea: [],

                province: 0,
                city: 0,
                area: 0,

                addressall: {},

                disabledcity: false,
                disabledarea: false,

                levelcity: true,
                levelarea: true,


            }
        },
        //props: ["provinceId", "cityId", "areaId", "level"],
        props: {
          isRequired: {
            type:Boolean,
            default:false
          },
          provinceId: {
            type:Number,
            default:0
          },
          cityId: {
            type:Number,
            default:0
          },
          areaId: {
            type:Number,
            default:0
          },
          level: {
            type:Number,
            default:0
          },
        },
        setup() {

            // Register module
            if (!store.hasModule('area')) store.registerModule('area', areaStore)

            // UnRegister on leave
            onUnmounted(() => {
                if (store.hasModule('area')) store.unregisterModule('area')
            })

            // 进入组件时初始化数据
            const edit = function () {
                // 初始化省市级别显示渲染组件
                if (this.level === 1){
                    this.levelcity = false
                    this.levelarea = false
                }else if (this.level === 2){
                    this.levelarea = false
                }

                // 拿到props的省市区id
                this.selData.provinceId = this.provinceId;
                this.selData.cityId = this.cityId;
                this.selData.areaId = this.areaId;

                if (this.selData.provinceId == null || this.selData.provinceId == '') {
                    this.disabledcity = true
                    this.disabledarea = true
                    this.selData.provinceId = '';
                    this.selData.cityId = '';
                    this.selData.areaId = '';
                } else if (this.selData.cityId == null || this.selData.cityId == '') {
                    this.disabledarea = true
                    this.selData.cityId = '';
                    this.selData.areaId = '';
                }else if (this.selData.areaId == null || this.selData.areaId == '') {
                    this.selData.areaId = '';
                }

                // 初始化全部地址
                store.dispatch('area/search').then(response => {
                    this.addressall = response.data.data
                    for (let i = 0; i < this.addressall.length; i++) {
                        // 初始化省份
                        if (this.addressall[i].area_parent_id == 0) {
                            this.getProvince.push({
                                "id": this.addressall[i].area_id,
                                "label": this.addressall[i].area_name,
                                "parentid": this.addressall[i].area_parent_id,
                            })
                        }

                        // 初始化城市
                        if (this.addressall[i].area_id == this.provinceId){
                            this.selData.provinceId = this.addressall[i].area_name
                            this.province = this.provinceId
                            for (let j = 0; j < this.addressall.length; j++) {
                                if (this.addressall[j].area_parent_id == this.provinceId) {
                                    this.getCity.push({
                                        "id": this.addressall[j].area_id,
                                        "label": this.addressall[j].area_name,
                                        "parentid": this.addressall[j].area_parent_id,
                                    })
                                }
                            }
                        }

                        // 初始化地区
                        if (this.addressall[i].area_id == this.cityId){
                            this.selData.cityId = this.addressall[i].area_name
                            this.city = this.cityId
                            for (let j = 0; j < this.addressall.length; j++) {
                                if (this.addressall[j].area_parent_id == this.cityId) {
                                    this.getArea.push({
                                        "id": this.addressall[j].area_id,
                                        "label": this.addressall[j].area_name,
                                        "parentid": this.addressall[j].area_parent_id,
                                    })
                                }
                            }
                        }

                        if (this.addressall[i].area_id == this.areaId){
                            this.selData.areaId = this.addressall[i].area_name
                            this.area = this.areaId
                        }

                    }
                })
            }


            // 选择省份后初始化城市options
            const onChangeProvince = function (event) {
                this.selData.cityId = ''
                this.selData.areaId = ''
                this.city = 0
                this.area = 0
                this.disabledarea = true

                if (this.selData.provinceId == null || this.selData.provinceId == '') {
                    this.province = 0
                    this.selData.provinceId = ''
                    this.disabledcity = true
                } else {
                    this.disabledcity = false

                    this.getCity.length = 0
                    this.province = event.id
                    for (let i = 0; i < this.addressall.length; i++) {
                        if (this.addressall[i].area_parent_id == this.province) {
                            this.getCity.push({
                                "id": this.addressall[i].area_id,
                                "label": this.addressall[i].area_name,
                                "parentid": this.addressall[i].area_parent_id,
                            })
                        }
                    }
                }
            }

            // 选择好城市后初始化地区options
            const onChangeCity = function (event) {
                this.area = 0
                this.selData.areaId = ''
                if (this.selData.cityId == null || this.selData.cityId == '') {
                    this.city = 0
                    this.selData.cityId = ''
                    this.disabledarea = true
                } else {
                    this.disabledarea = false

                    this.getArea.length = 0
                    this.city = event.id
                    for (let i = 0;i < this.addressall.length;i++){
                        if (this.addressall[i].area_parent_id == this.city){
                            this.getArea.push({
                                "id" : this.addressall[i].area_id,
                                "label" : this.addressall[i].area_name,
                                "parentid" : this.addressall[i].area_parentid_id
                            })
                        }
                    }
                }
            }

            const onChangeArea = function (event) {
                if (this.selData.areaId == null || this.selData.areaId == ''){
                    this.area = 0
                    this.selData.areaId = ''
                }else {
                    this.area = event.id
                }
            }

            return {
                onChangeProvince,
                onChangeCity,
                onChangeArea,
                getCodeOptions,
                edit,
            }
        },
        computed: {},
        methods: {
        },
        watch: {
        },
        mounted() {
        },
        created() {
            this.id = this.$route.query.id || 0;
            this.edit();
        },
    }
</script>

<style lang="scss">
    @import '@core/scss/vue/libs/vue-select.scss';
</style>
