import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function storeUseList() {
  // Use toast
  const toast = useToast()

  const refListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: 'ID', sortable: true },

    { key: 'store_id', label: 'ID'},
    { key: 'company_name', label: '渠道主体名称' },
    { key: 'companyname', label: '公司主体' },
    { key: 'store_name', label: '店铺名称' },
    { key: 'storeid', label: '店铺ID' },
    { key: 'store_status', label: '店铺状态' },
    { key: 'user_name', label: '店铺账户' },
    { key: 'user_pass', label: '密码' },
    { key: 'store_user', label: '店铺运营人' },
    { key: 'logintel_care', label: '登录手机保管人' },
    { key: 'get_cash_usetel', label: '提现绑定手机号' },
    { key: 'get_cash_status', label: '店铺提现状态' },
    { key: 'erp_code', label: 'ERP编码', },
    { key: 'returnmoney_companyname', label: '回款主体' },
    { key: 'invoice_oa_userid',label:'OA开票申请人'},
    { key: 'store_team', label: '品牌团队'},
    { key: 'status', label: '状态'},
    { key: 'is_noorder', label: '是否允许无源退', sortable: true },
    { key: 'create_time', label: '创建时间' },
    { key: 'creator', label: '创建人', },

    { key: 'actions', label: '操作' },
  ]

  const start = ref(1)
  const limit = ref(10)
  const limitOptions = [10, 25, 50, 100]
  const listTotals = ref(0)
  const searchKey = ref('')
  const orderBy = ref('store_id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: limit.value * (start.value - 1) + (localItemsCount ? 1 : 0),
      to: limit.value * (start.value - 1) + localItemsCount,
      of: listTotals.value,
    }
  })

  const refetchData = () => {
	  refListTable.value.refresh()
  }

  watch([start, limit, searchKey], () => {
    refetchData()
  })

  const searchList = (ctx, callback) => {
    const condition = {...store.getters['store/getCondition']}
    const params = {
      search_key: searchKey.value,
      start: start.value,
      limit: limit.value,
      order_by: orderBy.value,
      order_desc: isSortDirDesc.value === true ? 'desc':'',
    }
    Object.assign(params,condition)
    store
      .dispatch('store/searchAll', params)
      .then(response => {
        const data = response.data.data
        const list = data.ext.list
        listTotals.value = data.ext.totals

        callback(list)
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: '列表获取错误',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    searchList,
    tableColumns,
    limit,
    start,
    listTotals: listTotals,
    dataMeta,
    limitOptions,
    searchKey,
    orderBy,
    isSortDirDesc,
    refListTable,

    refetchData,

    // Extra Filters
  }
}
