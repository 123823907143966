<template>
  <b-card
      class="warehouse-edit-wrapper"
  >
    <!-- form -->
    <b-form id="warehouseForm" class="edit-form mt-2">
<!--      {{companyId}}{{companyNames}}-->
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>

        <!--仓库card-->
        <b-col md="4">
          <b-card header="仓库">
            <!--名称-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="名称"
                  label-for="warehouse_name"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="warehouse_name"
                    size="sm"
                    v-model="warehouse.warehouse_name"
                />
              </b-form-group>
            </b-col>
            <!--ERP编码-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="ERP编码"
                  label-for="erp_code"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="erp_code"
                    size="sm"
                    v-model="warehouse.erp_code"
                />
              </b-form-group>
            </b-col>
            <!--打印等分-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="打印等分"
                  label-for="label"
                  label-size="sm"
                  class="mb-1"
              >
                <v-select
                    id="print_page"
                    :options="getCodeOptions('print_page')"
                    :clearable="true"
                    v-model="printPageType"
                    @input="changeSelect('print_pagetype',$event)"
                    class="select-size-sm"
                    placeholder="请选择打印等分"
                />
              </b-form-group>
            </b-col>
            <!--备注-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="备注"
                  label-for="remark"
                  label-size="sm"
                  class="mb-1"
              >
                <b-form-input
                    id="remark"
                    size="sm"
                    v-model="warehouse.remark"
                />
              </b-form-group>
            </b-col>
          </b-card>
        </b-col>

        <!--地址card-->
        <b-col md="4">
          <b-card header="地址">
            <!--位置-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="位置"
                  label-for="location"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="location"
                    size="sm"
                    v-model="warehouse.location"
                />
              </b-form-group>
            </b-col>
            <!--省市区-->
            <b-col md="12">
              <area-select v-if="warehouse.loaded"
                           :provinceId="province_id"
                           :cityId="city_id"
                           :areaId="area_id"
                           ref="areaSel"
                           :level="level"
              />
            </b-col>
          </b-card>
        </b-col>

        <!--联系人card-->
        <b-col md="4">
          <b-card header="联系人">
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="联系人"
                  label-for="contact"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="user_id"
                    size="sm"
                    v-model="contact"
                    v-b-modal.modal-select-user
                    placeholder="点击选择联系人"
                    readonly=""
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="联系电话"
                  label-for="mobile"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="mobile"
                    size="sm"
                    v-model="warehouse.mobile"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="紧急联系人"
                  label-for="contact_other"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="contact_other"
                    size="sm"
                    v-model="warehouse.contact_other"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="紧急联系人电话"
                  label-for="mobile_other"
                  label-size="sm"
                  class="mb-1 required"
              >
                <b-form-input
                    id="mobile_other"
                    size="sm"
                    v-model="warehouse.mobile_other"
                />
              </b-form-group>
            </b-col>
          </b-card>
        </b-col>

        <!--店铺card-->
        <b-col md="4">
          <b-card header="店铺">
            <!--选择仓库-->
            <b-col md="12">
              <b-form-group
                  label-cols="3"
                  label-cols-lg="3"
                  label="店铺"
                  label-for="applier_name"
                  label-size="sm"
                  class="mb-1 "
              >
                <div
                    v-for="(p, index) in storeArr"
                    :key="index">
                  <b-form-input
                      id="applier_name"
                      size="sm"
                      v-b-modal.modal-select-stores
                      placeholder="点击搜索店铺"

                      v-model="storeArr[index].name"
                      :clearable="true"
                      style="width: 85%; float: left"
                  >
                  </b-form-input>
                  <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="flat-secondary"
                      style="margin-left: 5px"
                      @click="removeStore(index)" size="sm">
                    删除
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
          </b-card>
        </b-col>


        <b-modal
            id="modal-select-user"
            ok-only
            ok-title="确认"
            @ok="onSelectUser"
            cancel-title="取消"
            centered
            size="lg"
            title="选择姓名"
        >
          <user-select
              ref="myUserSelect">
          </user-select>


        </b-modal>


        <!-- 店铺弹出窗口 -->
        <b-modal
            id="modal-select-stores"
            ok-only
            ok-title="确认"
            @ok="onSelectStores"
            cancel-title="取消"
            centered
            size="xl"
            title="选择店铺"
        >
<!--          <StoreList ref="storeList">
          </StoreList>-->
          <div class="m-2">

            <!-- Table Top -->
            <b-row>

              <!-- Per Page -->
              <b-col
                  cols="12"
                  md="6"
                  class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>显示</label>
                <v-select
                    v-model="limit"
                    :options="limitOptions"
                    :clearable="false"
                    class="per-page-selector d-inline-block mx-50"
                />
                <label>条</label>
              </b-col>

              <!-- Search -->
              <b-col
                  cols="12"
                  md="6"
              >
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                      v-model="searchKey"
                      class="d-inline-block mr-1"
                      placeholder="搜索关键字..."
                  />
                </div>
              </b-col>
            </b-row>

          </div>

          <b-table
              ref="refListTable"
              class="position-relative"
              :items="searchList"
              responsive
              :fields="tableColumns"
              primary-key="id"
              :sort-by.sync="orderBy"
              show-empty
              empty-text="未找到记录"
              :sort-desc.sync="isSortDirDesc"
              select-mode="single"
              selectable
              @row-selected="selectAllRow"
          >

            <!-- Column: store_id -->
            <template #cell(store_id)="data">
              #{{ (data.item.store_id) }}
            </template>

            <!-- Column: create_time -->
            <template #cell(update_time)="data">
              {{ toTime(data.item.update_time) }}
            </template>

            <!-- Column: create_time -->
            <template #cell(expire_time_token)="data">
              {{ toTime(data.item.expire_time_token) }}
            </template>

            <!-- Column: State -->
            <template #cell(state)="data">
              {{getCodeLabel('state', data.item.state)}}
            </template>

            <!-- Column: image -->
            <template #cell(image)="data">
              <b-img :src="path + '/' + data.item.image" style="width: 40px; height: 40px" fluid alt="暂无图片"></b-img>
            </template>

          </b-table>
          <div class="mx-2 mb-2">
            <b-row>

              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
              </b-col>
              <!-- Pagination -->
              <b-col
                  cols="12"
                  sm="6"
                  class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >

                <b-pagination
                    v-model="start"
                    :total-rows="listTotals"
                    :per-page="limit"
                    first-number
                    last-number
                    class="mb-0 mt-1 mt-sm-0"
                    prev-class="prev-item"
                    next-class="next-item"
                >
                  <template #prev-text>
                    <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                    />
                  </template>
                </b-pagination>

              </b-col>

            </b-row>
          </div>
        </b-modal>


        <b-col
            cols="12"
            class="mt-50"
        >
          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
          >
            保存
          </b-button>
          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            返回
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
  </b-card>
</template>

<script>
import {
  BCard, BMedia, BAvatar, BCardText, BForm, BRow, BCol, BFormGroup, BFormInput, BImg, BFormFile, BLink, BButton,BPagination
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {onUnmounted, ref} from '@vue/composition-api'
import router from "@/router";
import store from "@/store";
import warehouseStore from './warehouseStore'
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor } from '@core/utils/filter'
import CompanySelect from "@/views/apps/company/CompanySelect";
import UserSelect from '@/views/apps/user/user-modal/UserSelect'

import areaStore from "@/views/apps/area/areaStore";
import AreaSelect from "@/views/apps/area/AreaSelect";
import StoreList from '@/views/apps/store/StoreList'
import storeUseList from '@/views/apps/store/storeUseList'
import storeStore from '@/views/apps/store/storeStore'
import Ripple from "vue-ripple-directive";

export default {
  components: {
    AreaSelect,
    CompanySelect,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    vSelect,
    useToast,
    UserSelect,
    StoreList,
    BPagination,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: ref(0),
      warehouse: ref({}),
      typeArray:[{"label":"我方","type":1},{"label":"对方","type":2}],
      type:{},
      companyName:{},
      companyId:0,
      companyNames:"",
      contact:"",

      province_id: '',
      city_id: '',
      area_id: '',
      level: 3,
      storeArr: [{
        id: null,
        name: ''
      }],
      path: null,
      selected: [],
      printPageType:{},
    }
  },
  props:{
    company_id:{
      type:Number,
      default:0,
    },
    company_name:{
      default:"",
    },
  },
  methods: {
    onSelectStores() {
      let rowVal = this.selected[0]
      let judge = true
      // 判断是否有重复的数据
      for (let i = 0; i < this.storeArr.length; i++) {
        if (this.storeArr[i].id === rowVal.store_id) {
          judge = false
        }
      }
      if (judge) {
        this.storeArr[this.storeArr.length-1].name = rowVal.store_name
        this.storeArr[this.storeArr.length-1].id = rowVal.store_id
        this.storeArr.push({
          id: null,
          name: '',
        })
      }
    }
  },
  setup() {

    const toast = useToast()

    // Register module

    if (!store.hasModule('store')) store.registerModule('store', storeStore)
    if (!store.hasModule('warehouse')) store.registerModule('warehouse', warehouseStore)
    if (!store.hasModule('area')) store.registerModule('area', areaStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('warehouse')) store.unregisterModule('warehouse')
      if (store.hasModule('area')) store.unregisterModule('area')
    })

    const edit = function() {
      store.dispatch('warehouse/edit', {id: this.id}).then(res => {
        this.warehouse = res.data.data
        // 判断是新增还是编辑

        if (this.id != 0){
          // 店铺回显 this.storeArr
          this.storeArr = this.warehouse.store_ids.length != 0? JSON.parse(this.warehouse.store_ids):this.storeArr
          if (this.storeArr.length == 1 ) {
            for(let i = 0; i < this.storeArr.length; i++) {
              if (this.storeArr[i].id != null) {
                this.storeArr.push({
                  id: null,
                  name: '',
                })
              }
            }
          } else {
            this.storeArr.push({
              id: null,
              name: '',
            })
          }
          this.province_id = this.warehouse.province_id
          this.city_id = this.warehouse.city_id
          this.area_id = this.warehouse.area_id
        }

        this.companyName = this.warehouse.company_name
        this.printPageType = getCode("print_page", this.warehouse.print_pagetype)

        this.warehouse.state = 1
        this.contact = this.warehouse.contact
        for (let i = 0; i < this.typeArray.length; i++) {
          if (this.warehouse.type === this.typeArray[i].type){
            this.type = this.typeArray[i].label
          }
        }
      })
    }

    const view = function() {
      store.dispatch('warehouse/view', {id: this.id}).then(res => {
        this.warehouse = res.data.data
      })
    }

    const cancel = function() {
      this.$router.go(-1)
    }

    const save = function() {
      let arr = []
      for (let i = 0; i < this.storeArr.length; i++) {
        if (this.storeArr[i].id == null) {
          continue
        }else{
          arr.push(this.storeArr[i])
        }
      }
      // 绑定仓库
      this.warehouse.store_ids = JSON.stringify(arr).length == 2 ? "": JSON.stringify(arr)

        if (this.$refs.areaSel.selData.provinceId === '' || this.$refs.areaSel.selData.provinceId === undefined){
          toast.error("请填写 省份!")
          return false
        }

        if (this.$refs.areaSel.selData.cityId === '' || this.$refs.areaSel.selData.cityId === undefined){
          toast.error("请填写 城市!")
          return false
        }

      if (this.$refs.areaSel.selData.areaId === '' || this.$refs.areaSel.selData.areaId === undefined){
        toast.error("请填写 地区!")
        return false
      }


      // 将HorizontalArea组件中的id传到WarehouseEdit中保存
      this.warehouse.province_id = this.$refs.areaSel.province
      this.warehouse.city_id = this.$refs.areaSel.city
      this.warehouse.area_id = this.$refs.areaSel.area

      // 将HorizontalArea组件中的省市名传到WarehouseEdit中保存
      if (typeof (this.$refs.areaSel.selData.provinceId) == "string"){
        this.warehouse.province = this.$refs.areaSel.selData.provinceId
      }else {
        this.warehouse.province = this.$refs.areaSel.selData.provinceId.label
      }

      if (typeof (this.$refs.areaSel.selData.cityId) == "string"){
        this.warehouse.city = this.$refs.areaSel.selData.cityId
      }else {
        this.warehouse.city = this.$refs.areaSel.selData.cityId.label
      }

      if (typeof (this.$refs.areaSel.selData.areaId) == "string"){
        this.warehouse.area = this.$refs.areaSel.selData.areaId
      }else {
        this.warehouse.area = this.$refs.areaSel.selData.areaId.label
      }

      if (this.companyId==0){
        this.warehouse.company_id = this.company_id
        this.warehouse.company_name = this.company_name
      }
      if (this.company_id==0){
        this.warehouse.company_id = this.companyId
        this.warehouse.company_name = this.companyNames
      }


      store.dispatch('warehouse/save', this.warehouse).then(res => {
        if (res.data.code == 0) {
          toast.success('数据已保存!')
          this.warehouse = res.data.data
          if (this.company_id==0){
            this.$router.push({ name: 'apps-warehouse-list',query:{id:this.companyId}});
          }
        } else {
          toast.error(res.data.data)
        }

      })
    }

    const changeType = function (event) {
      this.warehouse.type = event.type
    }

    const onSelectCompany = function(bvModalEvt) {
      let checked = this.$refs.myCompanySelects.getSelected()[0];
      console.log('parent checked: ', checked)
      this.warehouse.company_id = checked.company_id
      this.warehouse.company_name = checked.company_name
      this.companyName = checked.company_name
    }

    const onSelectUser = function (bvModalEvt) {
      let checked = this.$refs.myUserSelect.getSelected()[0];
      this.warehouse.contact = checked.full_name
      this.contact = checked.full_name
      this.warehouse.mobile = checked.mobile
      this.warehouse.user_id = checked.user_id
      // console.log(this.items)
    }

    const selectAllRow = function(items) {
      this.selected = items
    }

    const removeStore = function(index) {
      let a = this.storeArr[index]
      if (a.id != null) {
        let newArr = []
        for (let i = 0; i < this.storeArr.length; i++) {
          if (i === index) {
            continue
          }
          newArr.push(this.storeArr[i])
        }
        this.storeArr = newArr
      }
    }

    const changeSelect = function (key, event) {
      this.warehouse[key] = event==null?null:event.value
    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,

      // UI
    } = storeUseList()

    return {
      edit,
      view,
      cancel,
      save,

      // storeUseList
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      removeStore,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      changeType,
      onSelectCompany,
      onSelectUser,
      selectAllRow,
      toTime,
      changeSelect,
    }
  },
  created() {
    this.path = process.env.VUE_APP_OSS_URL

    this.id = this.$route.query.id || 0;
    this.companyId = this.$route.query.companyId || 0;
    this.companyNames = this.$route.query.companyName || "";
    this.edit()
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
